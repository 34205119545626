<template>
  <base-section id="startup-list" space="0">
    <v-container class="px-8">
      <v-fade-transition
        v-if="$store.state.cart.products && $store.state.cart.products[0]"
        group
        hide-on-leave
      >
        <v-row key="return">
          <v-col cols="12" class="pt-6 pb-1">
            <v-row align="center">
              <v-btn elevation="0" class="ml-2" small :to="`/menu`">
                <v-icon class="grey--text">mdi-arrow-left</v-icon>
              </v-btn>
              <span class="grey--text pl-3 text-h6">{{ $t("store") }}</span>
            </v-row>
            <v-divider class="mt-8" />
          </v-col>
        </v-row>
        <v-row key="title">
          <v-col cols="12" class="pb-0">
            <div
              class="d-flex justify-space-between justify-sm-start align-center align-sm-end flex-column flex-sm-row"
            >
              <h3 class="text-h4 my-3 my-sm-0 mx-sm-3">
                {{ $t("myRequest") }}: {{ orderTo }}
              </h3>
              <base-dialog
                :title="$t('changeOrderType')"
                customActivator
                v-if="!isKiosk"
              >
                <template #custom-activator="{ events: { handleOpenDialog } }">
                  <span
                    class="primary--text font-weight-medium cursor-pointer"
                    @click="handleOpenDialog"
                  >
                    {{ $t("changeOrderType") }}
                  </span>
                </template>
                <template #content>
                  <SettingOrder />
                </template>
              </base-dialog>
            </div>
            <v-divider class="mt-4" />
          </v-col>
        </v-row>
        <v-row
          v-for="(product, i) in $store.state.cart.products"
          :key="i"
          justify="center"
        >
          <v-col cols="12">
            <v-row class="my-3 rounded-lg startup-list-card" justify="center">
              <v-col class="pa-0">
                <base-img
                  v-if="product.image"
                  :aspect-ratio="1"
                  :src="`${product.image}`"
                  class="rounded-lg"
                  cover
                  max-width="12rem"
                />
                <base-img
                  v-else
                  :aspect-ratio="1"
                  :src="require('@/assets/no-image.png')"
                  class="rounded-lg"
                  max-width="12rem"
                />
              </v-col>
              <v-col cols="9" class="pl-5 pt-0">
                <h6 class="text-justify text-h6 mb-2">
                  {{ product.name }}
                </h6>
                <template v-if="product.items">
                  <p
                    v-for="item in product.items"
                    :key="item.id"
                    class="text-justify text-h6 mb-2"
                  >
                    - {{ item.name }} * {{ item.qty }}
                  </p>
                </template>
                <p>{{ product.details }}</p>
                <p
                  class="text-justify text-h6 font-weight-normal grey--text text--darken-2 mt-3 mb-0"
                >
                  {{ formatPrice(+product.total_price) }}
                </p>
                <v-col cols="12" class="mt-5">
                  <v-row justify="space-between" align="center">
                    <v-col cols="5" class="accent rounded-xl px-3">
                      <v-row justify="space-between" align="center">
                        <v-btn
                          :disabled="
                            product.qty == null || Number(product.qty) < 2
                          "
                          @click="
                            $store.dispatch('cart/decreaseProductQty', product)
                          "
                          color="transparent"
                          x-small
                          plain
                          fab
                          elevation="0"
                        >
                          <v-icon color="secondary">mdi-minus</v-icon>
                        </v-btn>
                        <span class="mb-0">{{ product.qty }}</span>
                        <v-btn
                          @click="
                            $store.dispatch('cart/increaseProductQty', product)
                          "
                          color="transparent"
                          x-small
                          fab
                          elevation="0"
                        >
                          <v-icon color="secondary">mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-btn
                      @click="$store.dispatch('cart/removeProduct', product)"
                      color="accent"
                      x-small
                      fab
                      elevation="0"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
            <v-divider class="mt-4" />
          </v-col>
        </v-row>
        <v-row v-if="orderTypeIsToHome" key="supplement">
          <v-col cols="12" class="py-0 text-end">
            <p>
              {{ $t("domicileSupplement") }}: +{{
                formatPrice(priceDomicileService)
              }}
            </p>
          </v-col>
        </v-row>
        <v-row key="total">
          <v-col cols="6" class="py-0 text-start">
            <span class="text-h5">Total</span>
          </v-col>
          <v-col cols="6" class="py-0 text-end">
            <span class="text-h5">
              {{
                formatPrice(
                  orderTypeIsToHome
                    ? +$store.getters["cart/totalPrice"] + priceDomicileService
                    : +$store.getters["cart/totalPrice"]
                )
              }}
            </span>
          </v-col>
        </v-row>

        <v-row key="details" v-if="!isKiosk">
          <v-col cols="12" class="pt-6">
            <span class="font-weight-bold">{{ $t("addNote") }}</span>
            <v-form v-model="isValid">
              <v-textarea
                v-model="details"
                @blur="handleSaveOrderDetailsWhenInputChange"
                :rules="rulesLetters"
                :height="100"
                rounded
                class="pt-2"
                flat
                solo
                outlined
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row key="actions">
          <v-col class="pt-4" cols="12">
            <template v-if="authRequiredForKioskApp">
              <LoginBtn />
              <v-alert
                text
                :value="authRequiredForKioskApp"
                type="info"
                dense
                border="left"
                class="mt-3"
              >
                {{ $t("authIsRequiredForOrderType") }}
              </v-alert>
            </template>
            <FinishOrderBtn
              v-else
              :conditionValid="validateDetails"
              :priceDomicileService="priceDomicileService"
            />
          </v-col>
          <v-col class="pt-4 pb-12" cols="12">
            <v-btn
              @click="$store.dispatch('cart/clearCart')"
              color="disabled"
              elevation="0"
              block
            >
              <span class="grey--text">{{ $t("emptyCart") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-fade-transition>

      <base-section v-else>
        <v-row v-if="loading" justify="center">
          <v-progress-circular
            :width="15"
            :size="100"
            color="rgba(184, 184, 184,0.4)"
            indeterminate
          />
        </v-row>
        <v-row v-else justify="center">
          <v-col cols="12" class="text-center">
            <v-icon size="120" class="mx-auto mb-6" color="primary lighten-2">
              mdi-cart-off
            </v-icon>
          </v-col>

          <v-col cols="12">
            <base-subheading
              class="text-center text--disabled"
              :title="$t('cartEmpty')"
            />
            <div class="text-center">
              <base-btn :to="`/menu`">{{ $t("addProducts") }}</base-btn>
            </div>
          </v-col>
        </v-row>
      </base-section>
    </v-container>
  </base-section>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import {
  orderTypeStoreGetters,
  orderTypeName,
} from "@/store/modules/orderType/constNames";
import {
  formatPrice,
  rulesLetters,
  isAuthenticated,
  getIsKioskSetting,
} from "@/helpers";

import { axiosInstance } from "@/plugins/axios";

export default {
  name: "ShoppingCartSection",
  components: {
    SettingOrder: () => import("@/components/shared/SettingOrder/SettingOrder"),
    FinishOrderBtn: () =>
      import("@/components/shared/FinishOrder/FinishOrderBtn"),
    LoginBtn: () =>
      import("@/components/shared/RequirementsOrder/buttons/LoginBtn"),
  },
  data: () => ({
    details: "",
    loading: false,
    isValid: true,
    rulesLetters,
    priceDomicileService: 0,
  }),
  async mounted() {
    const globalConfigs = await axiosInstance.get(
      "api/v1/settings/domicile_pickup/",
      {
        headers: {
          Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
          "Content-Type": "application/json",
          Site: process.env.VUE_APP_SITE,
          Client: "app",
        },
      }
    );
    const { price_domicile_service } = globalConfigs.data.data;
    this.priceDomicileService = Number(price_domicile_service);
  },

  methods: {
    formatPrice,
    handleSaveOrderDetailsWhenInputChange() {
      localStorage.setItem("orderDetail", this.details);
    },
  },

  computed: {
    isKiosk() {
      return getIsKioskSetting;
    },
    authRequiredForKioskApp() {
      return !isAuthenticated() && this.isKiosk;
    },
    validateDetails() {
      return this.isValid;
    },
    apiUrl() {
      return process.env.VUE_APP_API;
    },
    IHaveSelectedAnOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    orderTo() {
      const type = this.IHaveSelectedAnOrderType;
      return i18n.t(type);
    },
    orderTypeIsToHome() {
      return this.IHaveSelectedAnOrderType === orderTypeName.AT_HOME;
    },
  },
};
</script>

<style lang="sass">
.v-image
  transition: all .3s ease-in-out

.startup-list-card
  &:hover
    h6
      transition: color .3s
      color: var(--v-primary-base)

    .v-image
      transform: scale(1.035)

.cursor-pointer
  cursor: pointer
</style>
